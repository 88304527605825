import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './UnifiedFilters.css';

const UnifiedFilters = ({ filters, updateFilters }) => {
  const [propertyTypes, setPropertyTypes] = useState([]);
  
  useEffect(() => {
    Papa.parse('/data.csv', {
      download: true,
      header: true,
      complete: (results) => {
        const types = [...new Set(results.data.map(ad => ad.n_rooms).filter(type => type))];
        setPropertyTypes(types);
      },
    });
  }, []);

  const handlePropertyTypeChange = (type) => {
    const updatedTypes = filters.propertyTypes.includes(type)
      ? filters.propertyTypes.filter(t => t !== type)
      : [...filters.propertyTypes, type];
    updateFilters({ ...filters, propertyTypes: updatedTypes });
  };

  const handleRentOrSellChange = (event) => {
    updateFilters({ ...filters, rentOrSell: event.target.value });
  };

  const handlePriceChange = (event) => {
    const newValue = Number(event.target.value);
    updateFilters({ ...filters, priceRange: [newValue, filters.priceRange[1]] });
  };

  const handleYieldChange = (event) => {
    const newValue = Number(event.target.value);
    updateFilters({ ...filters, yieldRange: [newValue, filters.yieldRange[1]] });
  };

  return (
    <div className="unified-filters">
      <div className="filter-group">
        <h4>Property Types:</h4>
        {propertyTypes.map(type => (
          <label key={type}>
            <input
              type="checkbox"
              checked={filters.propertyTypes.includes(type)}
              onChange={() => handlePropertyTypeChange(type)}
            />
            {type}
          </label>
        ))}
      </div>
      <div className="filter-group">
        <h4>Rent or Sell:</h4>
        <select value={filters.rentOrSell} onChange={handleRentOrSellChange}>
          <option value="all">All</option>
          <option value="rent">Rent</option>
          <option value="sell">Sell</option>
        </select>
      </div>
      <div className="filter-group">
        <h4>Price:</h4>
        <div className="slider-container">
          <div className="slider-value">
            <span>${filters.priceRange[0]}</span>
          </div>
          <input 
            type="range"
            min="0"
            max="1000000"
            step="1000"
            value={filters.priceRange[0]}
            onChange={handlePriceChange}
          />
        </div>
      </div>
      <div className="filter-group">
        <h4>ROI:</h4>
        <div className="slider-container">
          <div className="slider-value">
            <span>{filters.yieldRange[0]}%</span>
          </div>
          <input 
            type="range"
            min="0"
            max="100"
            step="0.1"
            value={filters.yieldRange[0]}
            onChange={handleYieldChange}
          />
        </div>
      </div>
      {/* Add more filter options here as needed */}
    </div>
  );
};

export default UnifiedFilters;
